<template>
  <main>
    <p class="errors" v-if="error.status">{{error.message}}</p>
    <p class="success" v-if="success.status">
      {{success.message}}
      <router-link class="btn" to="/">Go Back</router-link>
    </p>
    <form action="" @submit.prevent="complaints" v-if="!success.status" ref="complaints">
      <input
        type="text"
        v-model="phone"
        placeholder="Enter your mobile number*"
        pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
        title="Please enter a valid Mobile Number"
        required
        :disabled="accountExist || disablePhone"
      >
      <input
        type="text"
        v-model="name"
        placeholder="Enter your name*"
        title="Please enter your name"
        required
        :disabled="accountExist"
        v-if="phoneVerified"
      >
      <input
        type="email"
        v-model="email"
        placeholder="Enter your email"
        title="Please enter a valid Mobile Number"
        v-if="phoneVerified"
      >
      <input
        type="text"
        v-model="pincode"
        required
        pattern="^\d{6}$"
        :disabled="accountExist"
        placeholder="Enter your pincode*"
        title="Please enter a valid pincode"
        v-if="newTicket"
      >
      <select v-model="type" v-if="newTicket" required >
        <option value="null" selected disabled>Select Enquiry Type*</option>
        <option value="availability">Product Enquiry - Availability</option>
        <option value="dealership">Dealership/Franchise</option>
        <option value="information">Product Information</option>
        <option value="location">Store Location</option>
        <option value="bulk">Bulk Purchase</option>
        <option value="career">Careers</option>
        <option value="others">Others</option>
      </select>
      <textarea v-model="description" placeholder="Enter Message" rows="4" v-if="newTicket"></textarea>
      <!-- <div class="upload-section" v-if="newTicket" >
        <label for="defect-image" @click.prevent="openUploadPhoto('defect-image')">
          {{images.defect.text}}
        </label>
          <input type="file" accept="image/*" @change="uploadPhoto($event, 'defect')" id="defect-image"  >
        <label for="barcode-image" @click.prevent="openUploadPhoto('barcode-image')">
          {{images.barcode.text}}
        </label>
          <input type="file" accept="image/*" @change="uploadPhoto($event, 'barcode')" id="barcode-image"  >
        <label for="mrp-image" @click.prevent="openUploadPhoto('mrp-image')">
          {{images.mrp.text}}
        </label>
          <input type="file" accept="image/*" @change="uploadPhoto($event, 'mrp')" id="mrp-image"  >
      </div> -->

      <button type="submit" class="btn" v-if="showFormButton" > {{buttonText}}</button>
    </form>
      <div v-if="accountExist" style="text-align: center">
        <p v-if="loadingTickets">Getting your tickets...</p>
        <ul class="tickets">
          <li v-for="ticket in tickets" :key="ticket.id" class="card">
            <table style="margin: 0 auto">
              <tr>
                <td style="text-align: right"><u>ID </u>:</td>
                <td style="text-align: left">{{ticket.id}}</td>
              </tr>
              <!-- <tr>
                <td style="text-align: right"><u>Status</u>:</td>
                <td style="text-align: left">{{ticket.custom_field_values.status.name}}</td>
              </tr> -->
              <tr>
                <td style="text-align: right"><u>Type Of Enquiry</u>:</td>
                <td style="text-align: left">{{ticket.custom_field_values.type_of_enquiry.name}}</td>
              </tr>
              <!-- <tr>
                <td style="text-align: right"><u>Product Serial</u>:</td>
                <td style="text-align: left">{{ticket.custom_field_values.mattress_serial_no_}}</td>
              </tr> -->
            </table>
          </li>
        </ul>
      </div>
    <button class="btn" v-if="accountExist && !showFormButton" @click="createNewComplaint">Submit New Enquiry</button>
  </main>
</template>

<script>
export default {
  data() {
    return {
      error: {
        status: false,
        message: null,
      },
      success: {
        status: false,
        message: null,
      },
      name: null,
      phone: '',
      email: null,
      pincode: null,
      description: null,
      type: null,
      phoneVerified: false,
      accountExist: false,
      newTicket: false,
      buttonText: 'Check Account',
      disablePhone: false,
      showFormButton: true,
      kelsaContact: null,
      loadingTickets: false,
      images: {
        defect: {
          text: 'Upload Defect Image',
          file: null,
          status: false,
        },
        barcode: {
          text: 'Upload Barcode Image',
          file: null,
          status: false,
        },
        mrp: {
          text: 'Upload MRP Image',
          file: null,
          status: false,
        },
      },
      tickets: [],
    };
  },
  methods: {
    async complaints() {
      if (this.$refs.complaints.checkValidity()) {
        this.buttonText = 'Loading....';
        this.error.status = false;
        this.success.status = false;
        if (this.accountExist && this.newTicket) {
          // here if user is already got then create a ticket
          try {
            const ticket = {
              id: this.kelsaContact.custom_field_values.contact1.id,
              name: this.name,
              email: this.email,
              pincode: this.pincode,
              type: this.type,
              phone: this.phone,
              description: this.description,
              images: this.images,
            };
            const req = await fetch(`${this.apiURL}enquiries`, {
              method: 'POST',
              body: JSON.stringify(ticket),
              ...this.reqOptions,
            });
            if (req.status === 200) {
              const res = await req.json();
              if (res.success) {
                this.$refs.complaints.reset();
                this.showFormButton = false;
                this.success.status = true;
                this.success.message = 'Thank You for reaching us. Your enquiry is registered. Our executive will reach you soon.';
                this.listKelsaComplaints(this.kelsaContact.custom_field_values.contact1.id);
                return;
              }
              if (!res.success) {
                this.error.status = true;
                this.error.message = res.message;
                this.buttonText = 'Submit Enquiry';
              }
            }
            if (req.status !== 200) {
              this.error.status = true;
              this.error.message = 'Something went wrong! Please try again later.';
              this.buttonText = 'Submit Enquiry';
            }
          } catch (error) {
            this.error.status = true;
            this.error.message = 'Something went wrong! Please try again later.';
            this.buttonText = 'Submit Enquiry';
          }
          // console.log('here we only have to create new ticket');
        }
        if (!this.accountExist && this.newTicket) {
          // here if user is already got then create a ticket
          try {
            const ticket = {
              name: this.name,
              email: this.email,
              pincode: this.pincode,
              type: this.type,
              phone: this.phone,
              description: this.description,
              images: this.images,
            };
            const req = await fetch(`${this.apiURL}enquiries`, {
              method: 'POST',
              body: JSON.stringify(ticket),
              ...this.reqOptions,
            });
            if (req.status === 200) {
              const res = await req.json();
              if (res.success) {
                this.$refs.complaints.reset();
                this.showFormButton = false;
                this.success.status = true;
                this.success.message = 'Thank You for reaching us. Your enquiry is registered. Our executive will reach you soon.';
                this.listKelsaComplaints(res.ticket.userID);
                return;
              }
              if (!res.success) {
                this.error.status = true;
                this.error.message = res.message;
                this.buttonText = 'Submit Enquiry';
              }
            }
            if (req.status !== 200) {
              this.error.status = true;
              this.error.message = 'Something went wrong! Please try again later.';
              this.buttonText = 'Submit Enquiry';
            }
          } catch (error) {
            this.error.status = true;
            this.error.message = 'Something went wrong! Please try again later.';
            this.buttonText = 'Submit Enquiry';
          }
          // console.log('here we will create a new ticket with new user');
        }
        if (!this.accountExist) {
          const user = await this.checkKelsaContact(this.phone);
          if (user) {
            // console.log(user);
            this.kelsaContact = user;
            this.phoneVerified = true;
            this.accountExist = true;
            this.showFormButton = false;
            this.buttonText = 'Submit Enquiry';
            this.name = user.name;
            this.pincode = user.custom_field_values.pincode;
            if (user.custom_field_values.contact_email && user.custom_field_values.contact_email.length > 0) {
              this.email = user.custom_field_values.contact_email[0];
            }
            console.log(user);
            this.listKelsaComplaints(user.custom_field_values.contact1.id);
          }
          if (!user && !this.error.status) {
            this.phoneVerified = true;
            this.buttonText = 'Submit Enquiry';
            this.disablePhone = true;
            this.newTicket = true;
          }
          // console.log(user);
        }
        // check if the phone number exist or not
      }
    },
    async createNewComplaint() {
      this.newTicket = true;

      this.showFormButton = true;
      this.success.status = false;
      this.success.message = null;
    },
    openUploadPhoto(id) {
      // console.log(id);
      const name = id.replace('-image', '');
      if (!this.images[name].status) {
        document.getElementById(id).click();
      }
    },
    async uploadPhoto(e, name) {
      this.error.status = false;
      this.success.status = false;
      // console.log(name);
      // console.log(e.target.files[0].name);
      // console.log(e.target.files[0].size);
      // console.log(encodeURIComponent(e.target.files[0].type));
      try {
        this.images[name].text = `Uploading... ${e.target.files[0].name}`;
        const fd = new FormData();
        fd.append('image', e.target.files[0]);
        const req = await fetch(`${this.apiURL}enquiries/upload-photo/${name}`, {
          method: 'POST',
          body: fd,
          headers: {
            'x-kurlon-kare-key': 'sample',
          },
        });
        if (req.status === 200) {
          const res = await req.json();
          if (res.success) {
            this.images[name].text = `Uploaded ${e.target.files[0].name}`;
            this.images[name].status = true;
            this.images[name].upload = res.upload;
            // console.log(res.upload);
          }
          if (!res.success) {
            this.error.status = true;
            this.error.message = 'Something went wrong! Unable to upload image.';
          }
          // await this.uploadPhotoToAWS(res.upload, e.target.files[0]);
          // console.log(res);
        }
        if (req.status !== 200) {
          this.error.status = true;
          this.error.message = 'Something went wrong! Unable to upload image.';
        }
      } catch (error) {
        this.error.status = true;
        this.error.message = 'Something went wrong! Unable to upload image.';
      }
    },
    async listKelsaComplaints(id) {
      // here we will list all the complaints in Kelsa
      // console.log(id);
      try {
        this.loadingTickets = true;
        const req = await fetch(`${this.apiURL}enquiries/tickets/${id}`, {
          method: 'GET',
          ...this.reqOptions,
        });
        if (req.status === 200) {
          const res = await req.json();
          if (res.success) {
            this.tickets = res.tickets;
            console.log(res.tickets);
          }
          this.loadingTickets = false;
        }
        // console.log(id);
      } catch (error) {
        this.loadingTickets = false;
        this.error.status = true;
        this.error.message = 'Something went wrong! Please try again later.';
      }
    },
    async checkKelsaContact(phone) {
      let user = null;
      // here we will check the contact in kelsa
      try {
        const req = await fetch(`${this.apiURL}enquiries/check-contact/${phone}`, {
          method: 'GET',
          ...this.reqOptions,
        });
        if (req.status === 200) {
          const res = await req.json();
          console.log(res);
          if (res.success) {
            user = res.data;
          }
        }
        return user;
      } catch (error) {
        this.error.status = true;
        this.error.message = 'Something went wrong! Please try again later.';
        this.buttonText = 'Check Account';
        return null;
      }
    },
  },
};
</script>

<style scoped>
p.errors{
  text-align: center;
  padding: .5rem 0;
  color: darkred;
  font-weight: 700;
}
p.success{
  text-align: center;
  padding: 1rem 0;
  color: darkgreen;
  font-weight: 700;
}
.tickets{
  list-style: none;
}
li.card{
  box-shadow: 0px 0px .4rem rgba(11,25,28,0.32);
  padding: 1rem;
  border-radius: .5rem;
  margin: .5rem 1rem;
}
input[type="file"] {
  display: none;
}
</style>
